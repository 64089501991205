<template>
    <div class="m-news m-sideblock">
        <div class="m-news-header m-sideblock-header">
            <!-- <i class="el-icon-message-solid"></i> -->
            <div class="u-left">
                <a class="u-title" :href="more_link" target="_blank">
                    <i class="u-icon el-icon-message-solid"></i>
                    <!-- <svg height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" v-bind:class="'u-icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M332.664 236.024c-5.068-15.4-19.946-30.468-36.121-30.187-15.639.272-28.631 9.62-35.428 22.357a5.691 5.691 0 01-10.23 0c-6.8-12.737-19.788-22.085-35.428-22.357-16.175-.281-31.052 14.792-36.12 30.187-16.268 49.414 34.306 82.148 69.791 94.671a20.58 20.58 0 0013.744 0c35.476-12.519 86.061-45.243 69.792-94.671z" fill="#f06292"/><g fill="#ffd54f"><path d="M125.852 335.868a11.962 11.962 0 01-17.218-3.684 147.677 147.677 0 013.136-152.857 12.02 12.02 0 0117.458-3.106l12.747 9.787a11.967 11.967 0 012.915 15.792 107.683 107.683 0 00-2.4 109.009 12.059 12.059 0 01-3.49 15.814z"/><path d="M63.179 396.455a11.978 11.978 0 01-17.027-3.243 250.158 250.158 0 013.839-274.588 12.028 12.028 0 0117.2-2.892l19.181 14.483a11.963 11.963 0 012.776 16.2A202.162 202.162 0 0085.9 366.266a12.034 12.034 0 01-3.141 16.255zM386.148 335.868a11.962 11.962 0 0017.218-3.684 147.677 147.677 0 00-3.136-152.857 12.02 12.02 0 00-17.458-3.106l-12.747 9.787a11.967 11.967 0 00-2.915 15.792 107.683 107.683 0 012.4 109.009 12.059 12.059 0 003.49 15.814z"/><path d="M448.821 396.455a11.978 11.978 0 0017.027-3.243 250.158 250.158 0 00-3.839-274.588 12.028 12.028 0 00-17.2-2.892l-19.181 14.483a11.963 11.963 0 00-2.776 16.2 202.162 202.162 0 013.248 219.851 12.034 12.034 0 003.141 16.255z"/></g></svg> -->
                    <!-- <svg-icon class-name="u-icon" icon-class="news" /> -->
                    最新动态
                </a>
                <div class="u-mode-list">
                    <span
                        :class="`u-mode u-mode-${mode} ${item.value == mode ? 'is-active' : ''}`"
                        v-for="item in mode_list"
                        :key="item.value"
                        @click="switchMode(item.value)"
                        >{{ item.label }}</span
                    >
                </div>
            </div>
            <a :href="more_link" class="u-more" target="_blank" rel="noopener noreferrer" title="查看全部">
                <i class="el-icon-more"></i>
            </a>
        </div>
        <ul class="m-news-list m-sideblock-list" v-if="data">
            <li v-for="(item, i) in data" :key="i">
                <em v-if="item.time">{{ dateFormat(item.time) }}</em>
                <a :href="item.url" target="_blank" rel="noopener noreferrer" :style="itemStyle(item)">{{
                    item.title
                }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
import { getGameNews } from "@/service/spider";
import { getPostsFree } from "@/service/index";
import dateFormat from "@/utils/dateFormat.js";
import { getChangelog } from "@/service/cms";
import { getLink } from "@jx3box/jx3box-common/js/utils";
import { all_map } from "@jx3box/jx3box-common/data/jx3_zlp.json";
export default {
    name: "IndexNews",
    components: {},
    data: function () {
        return {
            mode: "all",
            mode_list: [
                { label: "全部", value: "all" },
                { label: "游戏", value: "game" },
                { label: "技改", value: "skill_change" },
                { label: "魔盒", value: "box" },
            ],

            // 游戏
            all_links: {
                std: "https://jx3.xoyo.com/allnews/",
                origin: "https://jx3yq.xoyo.com/index/#/latest",
            },
            game_links: {
                std: "https://jx3.xoyo.com/allnews/",
                origin: "https://jx3yq.xoyo.com/index/#/latest",
            },
            skill_change_links: {
                std: "/pvp/changelog",
                origin: "/pvp/changelog",
            },
            game_data: [],

            // 魔盒
            box_links: {
                std: "/notice",
                origin: "/notice",
            },
            box_data: [],

            // 技改
            skill_change_data: [],
        };
    },
    computed: {
        client: function () {
            return this.$store.state.client;
        },
        more_link: function () {
            return this[this.mode + "_links"]?.[this.client] || "/";
        },
        all_data: function () {
            let list = [...this.game_data.slice(0, 3), ...this.box_data.slice(0, 2)];
            // list = list.sort((a, b) => {
            //     return b.time - a.time;
            // });
            return list.slice(0, 5);
        },
        data: function () {
            return this.mode == "all" ? this.all_data : this[this.mode + "_data"];
        },
        zlp_map() {
            // 生成对象 {key: value}
            return all_map.reduce((obj, item) => {
                obj[item.value] = item.label;
                return obj;
            }, {});
        },
    },
    methods: {
        dateFormat: function (val) {
            return dateFormat(val, "-");
        },
        linkFormat: function (val) {
            if (val.startsWith("/")) {
                return "https://jx3.xoyo.com" + val;
            } else {
                return val;
            }
        },
        switchMode: function (val) {
            this.mode = val;
        },
        loadGameData: function () {
            getGameNews(this.client).then((res) => {
                // 正式服
                // if (this.client == "std") {
                const data = this.client == "std" ? res?.data : res?.data?.reverse();
                this.game_data = res?.data
                    .map((item) => {
                        item.url = this.linkFormat(item.url);
                        // 如果当前为1月，且新闻时间为12月，则年份-1
                        item.time =
                            !new Date().getMonth() && item.item?.split("/")[0] == 12
                                ? (item.time = new Date(new Date().getFullYear() - 1 + "/" + item.time))
                                : new Date(new Date().getFullYear() + "/" + item.time);
                        item.type = "game";
                        return item;
                    })
                    .slice(0, 5);
                // }
                // else {
                //     // 怀旧服
                //     let data = res?.data;
                //     let list = [];
                //     for (let group in data) {
                //         list.push(...data[group].list);
                //     }
                //     this.game_data = list
                //         .map((item) => {
                //             item.time = new Date(Number(item.inputtime * 1000));
                //             item.type = "game";
                //             return item;
                //         })
                //         .sort((a, b) => {
                //             return ~~b.inputtime - ~~a.inputtime;
                //         })
                //         .slice(0, 5);
                // }
            });
        },
        loadBoxData: function () {
            getPostsFree({
                client: this.client,
                type: "notice",
                per: 5,
                sticky: 1,
            }).then((res) => {
                this.box_data = res.data.data?.list?.map((item) => {
                    item.title = item.post_title;
                    item.url = `/notice/${item.ID}`;
                    item.time = new Date(item.post_modified);
                    item.type = "box";
                    return item;
                });
            });
        },
        loadSkillChangeData: function () {
            const params = {
                client: this.client,
            };
            getChangelog(params).then((res) => {
                this.skill_change_data = (res.data.data?.list || []).slice(0, 5).map((item) => {
                    item.title = `【${this.zlp_map[item.zlp]}】${item.title}`;
                    item.url = item.link || getLink("bps", item.post_id);
                    item.time = new Date(item.date);
                    item.type = "skill_change";
                    return item;
                });
            });
        },
        itemStyle(item) {
            if (item?.color) {
                return {
                    color: item.color,
                    fontWeight: "bold",
                };
            }
            return {};
        },
    },
    mounted: function () {
        this.loadGameData();
        this.loadBoxData();
        this.loadSkillChangeData();
    },
};
</script>

<style lang="less">
@import "~@/assets/css/v3/news.less";
</style>
